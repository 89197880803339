@import '/src/styles/utilities.scss';

// https://icons.getbootstrap.com/icons/check/
$check-icon: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='white' viewBox='0 0 16 16'><path d='M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z'/></svg>");
$checkbox-focus-outline-width: 2px;
$checkbox-size: 2rem;

.checkbox {
  display: inline-flex;
  align-items: center;

  // Prevent clipping of the focus outline by reserving space for it
  padding: $checkbox-focus-outline-width;
}

.checkbox__label {
  @include apply-display-styles('subtitle1');

  flex: 1 1 auto;
  max-width: 100%;

  // Use padding instead of gap to allow selecting by clicking in whitespace
  padding-left: $space-12;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover:not(:disabled) {
    cursor: pointer;
  }
}

.checkbox__input {
  width: $checkbox-size;
  min-width: $checkbox-size;
  height: $checkbox-size;
  padding: 0;
  margin: 0;
  appearance: none;
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-500);
  border-radius: 4px;
  outline: none;
  transition: all 0.1s;

  &:checked {
    background-color: var(--color-gocomics-blue);
    background-image: $check-icon;
    background-size: cover;
    border-color: var(--color-gocomics-blue);
  }

  &:focus {
    outline: $checkbox-focus-outline-width solid var(--color-blue-400);
  }

  &:hover:not(:disabled) {
    cursor: pointer;
  }
}
